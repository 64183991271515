import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";

const Card = styled(Box)<{
  width?: string;
  padding?: string;
  border?: string;
  $borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "100%"};
  padding: ${({ padding }) => padding ?? "1rem"};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? "16px"};
  border: ${({ border }) => border};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.backgroundInteractive};
  background-color: ${({ theme }) => theme.bg6};
`;

export const GrayCard = styled(Card)`
  background-color: ${({ theme }) => theme.deprecated_bg3};
`;

export const DarkGrayCard = styled(Card)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
`;

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg0};
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.deprecated_bg3};
`;

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.deprecated_yellow3};
  font-weight: 500;
`;

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => theme.deprecated_primary5};
  color: ${({ theme }) => theme.accentAction};
  border-radius: 12px;
`;

export const RadiantPrimaryCard = styled(Card)`
  color: ${({ theme }) => theme.textPrimary};
  background: linear-gradient(180deg, #084da6 0%, #00072a 118%);
  border-radius: 10px;
`;

export const RadiantSecondaryCard = styled(Card)`
  color: ${({ theme }) => theme.textPrimary};
  background: radial-gradient(
    100% 100% at 50% 0%,
    rgba(6, 153, 126, 0.83) 0%,
    rgba(22, 6, 64, 0) 100%
  );
  border-radius: 20px 20px 0px 0px;
`;

export const BluePrimaryCard = styled(Card)`
  box-sizing: border-box;
  background: #131438;
  backdrop-filter: blur(40px);
  border-radius: 20px;
  border-top: 1px outset ${({ theme }) => theme.primary2};
  border-left: 1px outset ${({ theme }) => theme.primary2};
  border-bottom: 1px outset ${({ theme }) => theme.primary2};
  border-right: 1px outset #454866;
`;

export const BorderCard = styled.div`
  height: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    padding: 7rem;
    right: 1rem;
    bottom: 10px;
    left: 1rem;
    background-image: url(${process.env.PUBLIC_URL}/images/border.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    &::before {
      padding: 1rem;
    }
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    &::before {
      padding: 1rem;
    }
  `};
`;

export const BorderCardSecondary = styled.div`
  height: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 1px;
    padding: 1rem;
    right: 1rem;
    bottom: 10px;
    left: 1rem;
    background-image: url(${process.env.PUBLIC_URL}/images/border-large.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    &::before {
      padding: 1rem;
    }
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    &::before {
      padding: 1rem;
    }
  `};
`;
