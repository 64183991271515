import { ChainId, SupportedChainsType } from "@uniswap/sdk-core";

export const UniWalletSupportedChains = [ChainId.POLYGON];

export enum SupportedChainId {
  BSC = 56,
  BSC_TESTNET = 97,
  /* POLYGON = 137,
  POLYGON_MUMBAI = 80001, */
}

export const CHAIN_IDS_TO_NAMES = {
  [ChainId.MAINNET]: "mainnet",
  [ChainId.GOERLI]: "goerli",
  [ChainId.SEPOLIA]: "sepolia",
  [ChainId.POLYGON]: "polygon",
  [ChainId.POLYGON_MUMBAI]: "polygon_mumbai",
  [ChainId.CELO]: "celo",
  [ChainId.CELO_ALFAJORES]: "celo_alfajores",
  [ChainId.ARBITRUM_ONE]: "arbitrum",
  [ChainId.ARBITRUM_GOERLI]: "arbitrum_goerli",
  [ChainId.OPTIMISM]: "optimism",
  [ChainId.OPTIMISM_GOERLI]: "optimism_goerli",
  [ChainId.BNB]: "bnb",
  [ChainId.AVALANCHE]: "avalanche",
} as const;

export const SUPPORTED_CHAINS = [
  // SupportedChainId.POLYGON,
  // SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.BSC,
  SupportedChainId.BSC_TESTNET,
];

export function isSupportedChain(
  chainId: number | null | undefined | ChainId
): chainId is SupportedChainsType {
  return !!chainId && SUPPORTED_CHAINS.indexOf(chainId) !== -1;
}

export function asSupportedChain(
  chainId: number | null | undefined | ChainId
): SupportedChainsType | undefined {
  return isSupportedChain(chainId) ? chainId : 56;
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.POLYGON,
  ChainId.CELO,
  ChainId.OPTIMISM,
  ChainId.ARBITRUM_ONE,
  ChainId.BNB,
  ChainId.AVALANCHE,
] as const;

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS = [
  ChainId.POLYGON,
  ChainId.OPTIMISM,
  ChainId.ARBITRUM_ONE,
  ChainId.BNB,
  ChainId.ARBITRUM_GOERLI,
  ChainId.AVALANCHE,
] as const;

export const TESTNET_CHAIN_IDS = [
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.POLYGON_MUMBAI,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM_GOERLI,
  ChainId.CELO_ALFAJORES,
] as const;

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  // ChainId.MAINNET,
  // ChainId.GOERLI,
  // ChainId.SEPOLIA,
  // ChainId.POLYGON,
  // ChainId.POLYGON_MUMBAI,
  // ChainId.CELO,
  // ChainId.CELO_ALFAJORES,
  ChainId.BNB,
  /* ChainId.AVALANCHE, */
] as const;

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number];

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
] as const;

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number];

export function isPolygonChain(
  chainId: number
): chainId is ChainId.POLYGON | ChainId.POLYGON_MUMBAI {
  return chainId === ChainId.POLYGON || chainId === ChainId.POLYGON_MUMBAI;
}

/**
 * Get the priority of a chainId based on its relevance to the user.
 * @param {ChainId} chainId - The chainId to determine the priority for.
 * @returns {number} The priority of the chainId, the lower the priority, the earlier it should be displayed, with base of MAINNET=0.
 */
export function getChainPriority(chainId: ChainId): number {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.GOERLI:
    case ChainId.SEPOLIA:
      return 4;
    case ChainId.POLYGON:
    case ChainId.POLYGON_MUMBAI:
      return 1;
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARBITRUM_GOERLI:
      return 2;
    case ChainId.OPTIMISM:
    case ChainId.OPTIMISM_GOERLI:
      return 3;
    case ChainId.BNB:
      return 0;
    case ChainId.AVALANCHE:
      return 5;
    case ChainId.CELO:
    case ChainId.CELO_ALFAJORES:
      return 6;
    default:
      return 7;
  }
}

export function isUniswapXSupportedChain(chainId: number) {
  return chainId === ChainId.MAINNET;
}
