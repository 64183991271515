/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import BigNumber from "bignumber.js";
import { ButtonLight, ButtonPrimary } from "components/Button";
import { LightCard } from "components/Card";
import Modal from "components/Modal";
import { Input as NumericalInput } from "components/NumericalInput";
import { RowBetween } from "components/Row";
import { formatDistanceToNowStrict } from "date-fns";
import { enGB, es } from "date-fns/locale";
import { useActiveLocale } from "hooks/useActiveLocale";
import { useGbcPoolContract } from "hooks/useContract";
import { useCallback, useMemo, useState } from "react";
import { Info, X } from "react-feather";
import styled from "styled-components/macro";
import { ThemedText } from "theme";
import { DEFAULT_TOKEN_DECIMALS } from "utils/bigNumber";
import { getBalanceAmount, getFullDisplayBalance } from "utils/formatBalance";

import { PublicPoolData } from "../types";
import { WalletBalancesData } from "../types";
import { WalletPoolData } from "../types";

const ModalWrapper = styled.div`
  width: 100%;
  padding: 24px;
`;

const StyledClosed = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.textPrimary};
  display: flex;
  justify-content: right;

  :hover {
    cursor: pointer;
  }
`;

const StyledInput = styled(NumericalInput)`
  background-color: transparent;
  text-align: left;
  width: 100%;
  font-size: 24px;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 16px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    font-size: 12px;
  `};
`;

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.deprecated_blue4};
  border-radius: 12px;
  width: 100%;
  padding: 16px;
  margin-top: 1rem;
`;

interface UnstakeModalProps {
  walletData: WalletBalancesData;
  poolData: PublicPoolData;
  walletPoolData: WalletPoolData;
  onDismiss: () => void;
  isOpen: boolean;
}

export function UnstakeModal({
  walletData,
  poolData,
  walletPoolData,
  onDismiss,
  isOpen,
}: UnstakeModalProps) {
  const [val, setVal] = useState("");
  const [unStaking, setUnStaking] = useState(false);
  const activeLocale = useActiveLocale();

  const currentTimestamp = () => new Date().getTime() / 1000;

  const poolContract = useGbcPoolContract();

  const { stakedBal, unlockTime } = walletPoolData;

  const bigAmount = useMemo(() => {
    return new BigNumber(val);
  }, [val]);

  const valueWithTokenDecimals = bigAmount.times(DEFAULT_TOKEN_DECIMALS);

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(stakedBal);
  }, [stakedBal]);

  const handleChange = useCallback(
    (value: string) => {
      setVal(value.replace(/,/g, "."));
    },
    [setVal]
  );

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance);
  }, [fullBalance, setVal]);

  const handleUnstake = useCallback(async () => {
    try {
      setUnStaking(true);
      let amount = valueWithTokenDecimals;
      if (valueWithTokenDecimals.gt(stakedBal)) {
        amount = stakedBal;
      }
      const tx = await poolContract?.withdrawn(amount.toFixed(0));
      await tx?.wait();
      walletData.fetchWalletData();
      poolData.fetchPublicPoolData();
      walletPoolData.fetchWalletPoolData();
      setUnStaking(false);
      onDismiss();
    } catch (e) {
      setUnStaking(false);
      console.error(e);
    } finally {
      setUnStaking(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolContract, stakedBal, valueWithTokenDecimals]);

  const isPrimaryEnabled = () => {
    if (currentTimestamp() < unlockTime) {
      return false;
    }
    if (!val) {
      return false;
    }
    if (valueWithTokenDecimals.eq(0)) {
      return false;
    }
    if (valueWithTokenDecimals.gt(stakedBal)) {
      return false;
    }
    if (unStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    if (currentTimestamp() < unlockTime) {
      return t`Locked`;
    }
    if (!val) {
      return t`Enter Amount`;
    }
    if (valueWithTokenDecimals.eq(0)) {
      return t`Enter Amount`;
    }
    if (valueWithTokenDecimals.gt(stakedBal)) {
      return t`Insufficient balance`;
    }

    if (unStaking) {
      return t`Unstaking...`;
    }

    return t`Unstake`;
  };

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss}>
      <ModalWrapper>
        <RowBetween>
          <ThemedText.MediumHeader>
            <Trans>Unstake</Trans> DTA
          </ThemedText.MediumHeader>
          <StyledClosed>
            <X size={24} onClick={onDismiss} />
          </StyledClosed>
        </RowBetween>
        <ModalBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "20%",
            }}
          >
            <Info size={28} color="#FFD300" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%",
              color: "#FFD300",
            }}
          >
            <ThemedText.DeprecatedBody
              textAlign="left"
              color="#FFD300"
              ml="4px"
            >
              <Trans>
                The minimum amount to remain staked is 5000 DTA, if you withdraw
                funds and the remaining amount is less, all funds will be
                withdrawn
              </Trans>
              .
            </ThemedText.DeprecatedBody>
          </div>
        </ModalBody>
        {currentTimestamp() < unlockTime && (
          <ThemedText.DeprecatedBody textAlign="center" mt="1rem">
            <Trans>The lock will end</Trans>{" "}
            {formatDistanceToNowStrict(unlockTime * 1000, {
              addSuffix: true,
              locale: activeLocale === "es-ES" ? es : enGB,
            })}
          </ThemedText.DeprecatedBody>
        )}
        <LightCard $borderRadius="8px" mt="2rem">
          <RowBetween>
            <StyledInput
              className="amount"
              type="number"
              step={0.0}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={val}
              onUserInput={handleChange}
            />
            <ButtonLight
              width="20%"
              height="10px"
              disabled={stakedBal.eq(0)}
              onClick={handleSelectMax}
            >
              Max
            </ButtonLight>
          </RowBetween>
        </LightCard>
        <ThemedText.DeprecatedBody textAlign="right" mt="1rem">
          <Trans>Balance</Trans>: {getBalanceAmount(stakedBal).toString()} DTA
        </ThemedText.DeprecatedBody>
        <ButtonPrimary
          mt="1rem"
          disabled={!isPrimaryEnabled()}
          onClick={handleUnstake}
        >
          {getPrimaryText()}
        </ButtonPrimary>
      </ModalWrapper>
    </Modal>
  );
}
