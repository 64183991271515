// Based mostly on https://github.com/Uniswap/interface/blob/main/src/theme/index.tsx
import { opacify } from "./utils";

export const colors = {
  white: "#FFFFFF",
  black: "#000000",
  gray50: "#F5F6FC",
  gray100: "#E8ECFB",
  gray150: "#D2D9EE",
  gray200: "#B8C0DC",
  gray250: "#A6AFCA",
  gray300: "#98A1C0",
  gray350: "#888FAB",
  gray400: "#7780A0",
  gray450: "#6B7594",
  gray500: "#5D6785",
  gray550: "#505A78",
  gray600: "#404A67",
  gray650: "#333D59",
  gray700: "#293249",
  gray750: "#1B2236",
  gray800: "#131A2A",
  gray850: "#0E1524",
  gray900: "#0D111C",
  gray950: "#080B11",
  pink50: "#1f5e2d",
  pink100: "#1f5e2d",
  pink200: "#1f5e2d",
  pink300: "#1f5e2d",
  pink400: "#1f5e2d",
  pink500: "#1f5e2d",
  pink600: "#1f5e2d",
  pink700: "#1f5e2d",
  pink800: "#1f5e2d",
  pink900: "#1f5e2d",
  pinkVibrant: "#1f5e2d",
  red50: "#FAECEA",
  red100: "#FED5CF",
  red200: "#FEA79B",
  red300: "#FD766B",
  red400: "#FA2B39",
  red500: "#C4292F",
  red600: "#891E20",
  red700: "#530F0F",
  red800: "#380A03",
  red900: "#240800",
  redVibrant: "#F14544",
  yellow50: "#F6F2D5",
  yellow100: "#DBBC19",
  yellow200: "#DBBC19",
  yellow300: "#BB9F13",
  yellow400: "#A08116",
  yellow500: "#866311",
  yellow600: "#5D4204",
  yellow700: "#3E2B04",
  yellow800: "#231902",
  yellow900: "#180F02",
  yellowVibrant: "#FAF40A",
  // TODO: add gold 50-900
  gold200: "#EEB317",
  gold400: "#B17900",
  goldVibrant: "#FEB239",
  green50: "#E3F3E6",
  green100: "#BFEECA",
  green200: "#76D191",
  green300: "#40B66B",
  green400: "#209853",
  green500: "#0B783E",
  green600: "#0C522A",
  green700: "#053117",
  green800: "#091F10",
  green900: "#09130B",
  greenVibrant: "#5CFE9D",
  blue50: "#EDEFF8",
  blue100: "#03F1F6",
  blue200: "#ADBCFF",
  blue300: "#869EFF",
  blue400: "#050B28",
  blue500: "#1267D6",
  blue600: "#1D4294",
  blue700: "#09265E",
  blue800: "#0B193F",
  blue900: "#040E34",
  blueVibrant: "#587BFF",
  // TODO: add magenta 50-900
  magenta300: "#FD82FF",
  magentaVibrant: "#1f5e2d",
  purple300: "#8440F2",
  purple900: "#1C0337",
  purpleVibrant: "#6100FF",
  // TODO: add all other vibrant variations
  networkEthereum: "#627EEA",
  networkOptimism: "#FF0420",
  networkOptimismSoft: "rgba(255, 4, 32, 0.16)",
  networkPolygon: "#A457FF",
  networkArbitrum: "#28A0F0",
  networkBsc: "#F0B90B",
  networkPolygonSoft: "rgba(164, 87, 255, 0.16)",
  networkEthereumSoft: "rgba(98, 126, 234, 0.16)",
};

type Theme = typeof darkTheme;

const commonTheme = {
  white: colors.white,
  black: colors.black,

  chain_1: colors.networkEthereum,
  chain_3: colors.yellow400,
  chain_4: colors.pink400,
  chain_5: colors.green400,
  chain_10: colors.networkOptimism,
  chain_137: colors.networkPolygon,
  chain_42: colors.networkArbitrum,
  chain_56: colors.networkBsc,
  chain_420: colors.networkOptimism,
  chain_42161: colors.networkArbitrum,
  chain_421613: colors.networkArbitrum,
  chain_80001: colors.networkPolygon,
  chain_43114: colors.networkOptimism,
  chain_137_background: colors.purple900,
  chain_10_background: colors.red900,
  chain_43114_background: colors.red900,
  chain_42161_background: colors.blue900,
  chain_56_background: colors.networkBsc,
  promotional: colors.magenta300,

  brandedGradient: "linear-gradient(139.57deg, #FF79C9 4.35%, #FFB8E2 96.44%);",
  promotionalGradient:
    "radial-gradient(101.8% 4091.31% at 0% 0%, #4673FA 0%, #9646FA 100%);",

  hoverState: opacify(24, colors.blue200),
  hoverDefault: opacify(8, colors.gray300),
};

export const darkTheme = {
  ...commonTheme,

  userThemeColor: colors.magentaVibrant,

  background: colors.gray800,
  backgroundBackdrop: colors.gray950,
  backgroundSurface: colors.gray900,
  backgroundModule: colors.gray800,
  backgroundInteractive: colors.gray700,
  backgroundFloating: opacify(12, colors.black),
  backgroundOutline: opacify(24, colors.gray300),
  backgroundScrim: opacify(72, colors.gray900),
  backgroundScrolledSurface: opacify(72, colors.gray900),

  textPrimary: colors.white,
  textSecondary: colors.gray300,
  textTertiary: colors.gray500,

  accentAction: colors.blue100,
  accentActive: colors.blue100,
  accentSuccess: colors.green200,
  accentWarning: colors.gold200,
  accentFailure: colors.red300,
  accentCritical: colors.red300,

  accentActionSoft: opacify(24, colors.blue400),
  accentActiveSoft: opacify(24, colors.blue400),
  accentSuccessSoft: opacify(24, colors.green400),
  accentWarningSoft: opacify(24, colors.gold200),
  accentFailureSoft: opacify(12, colors.red300),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.gray50,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow:
    "12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);",
  shallowShadow:
    "4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);",

  networkDefaultShadow: `0px 40px 120px ${opacify(16, colors.blue400)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: `rgba(255,255,255,0.07)`,
  searchOutline: `rgba(255,255,255,0.07)`,
};

export const lightTheme: Theme = {
  ...commonTheme,

  userThemeColor: colors.magentaVibrant,

  background: colors.gray800,
  backgroundBackdrop: colors.gray950,
  backgroundSurface: colors.gray900,
  backgroundModule: colors.gray800,
  backgroundInteractive: colors.gray700,
  backgroundFloating: opacify(12, colors.black),
  backgroundOutline: opacify(24, colors.gray300),
  backgroundScrim: opacify(72, colors.gray900),
  backgroundScrolledSurface: opacify(72, colors.gray900),

  textPrimary: colors.white,
  textSecondary: colors.gray300,
  textTertiary: colors.gray500,

  accentAction: colors.blue400,
  accentActive: colors.blue400,
  accentSuccess: colors.green200,
  accentWarning: colors.gold200,
  accentFailure: colors.red300,
  accentCritical: colors.red300,

  accentActionSoft: opacify(24, colors.blue400),
  accentActiveSoft: opacify(24, colors.blue400),
  accentSuccessSoft: opacify(24, colors.green400),
  accentWarningSoft: opacify(24, colors.gold200),
  accentFailureSoft: opacify(12, colors.red300),

  accentTextDarkPrimary: opacify(80, colors.gray900),
  accentTextDarkSecondary: opacify(60, colors.gray900),
  accentTextDarkTertiary: opacify(24, colors.gray900),

  accentTextLightPrimary: colors.gray50,
  accentTextLightSecondary: opacify(72, colors.gray50),
  accentTextLightTertiary: opacify(12, colors.gray50),

  deepShadow:
    "12px 16px 24px rgba(0, 0, 0, 0.24), 12px 8px 12px rgba(0, 0, 0, 0.24), 4px 4px 8px rgba(0, 0, 0, 0.32);",
  shallowShadow:
    "4px 4px 10px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.12), 1px 2px 2px rgba(0, 0, 0, 0.12);",

  networkDefaultShadow: `0px 40px 120px ${opacify(16, colors.blue400)}`,

  stateOverlayHover: opacify(8, colors.gray300),
  stateOverlayPressed: opacify(24, colors.gray200),

  searchBackground: `rgba(255,255,255,0.07)`,
  searchOutline: `rgba(255,255,255,0.07)`,
};
