import { ChainSelector } from "components/NavBar/ChainSelector";
import Web3Status from "components/Web3Status";
import styled from "styled-components/macro";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
`;

export default function MenuMobile() {
  return (
    <Container>
      <ChainSelector />
      <Web3Status />
      {/* <MenuDropdown /> */}
    </Container>
  );
}
