/* eslint-disable import/no-unused-modules */
import { Contract } from "@ethersproject/contracts";
import {
  ARGENT_WALLET_DETECTOR_ADDRESS,
  ENS_REGISTRAR_ADDRESSES,
  MULTICALL_ADDRESSES,
  NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
  QUOTER_ADDRESSES,
  TICK_LENS_ADDRESSES,
  V2_ROUTER_ADDRESS,
  V3_MIGRATOR_ADDRESSES,
} from "@uniswap/sdk-core";
import QuoterV2Json from "@uniswap/swap-router-contracts/artifacts/contracts/lens/QuoterV2.sol/QuoterV2.json";
import IUniswapV2PairJson from "@uniswap/v2-core/build/IUniswapV2Pair.json";
import IUniswapV2Router02Json from "@uniswap/v2-periphery/build/IUniswapV2Router02.json";
import QuoterJson from "@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json";
import TickLensJson from "@uniswap/v3-periphery/artifacts/contracts/lens/TickLens.sol/TickLens.json";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";
import NonfungiblePositionManagerJson from "@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json";
import V3MigratorJson from "@uniswap/v3-periphery/artifacts/contracts/V3Migrator.sol/V3Migrator.json";
import { useWeb3React } from "@web3-react/core";
import ARGENT_WALLET_DETECTOR_ABI from "abis/argent-wallet-detector.json";
import DATA_PRESALE_NFT_ABI from "abis/DataNFTSale.json";
import EIP_2612 from "abis/eip_2612.json";
import ENS_PUBLIC_RESOLVER_ABI from "abis/ens-public-resolver.json";
import ENS_ABI from "abis/ens-registrar.json";
import ERC20_ABI from "abis/erc20.json";
import ERC20_BYTES32_ABI from "abis/erc20_bytes32.json";
import ERC721_ABI from "abis/erc721.json";
import ERC1155_ABI from "abis/erc1155.json";
import GBC_POOL_ABI from "abis/GreenBlockCapitalPool.json";
import NFT_CAVE_ABI from "abis/GreenBlockCave.json";
import CAVE_POOL_ABI from "abis/GreenBlockCavePool.json";
import NFT_CONVERSOR_ABI from "abis/GreenBlockConversor.json";
import DISTRIBUTOR_ABI from "abis/GreenBlockDistributor.json";
import LOCKER_ABI from "abis/GreenBlockJamonStake.json";
import NFT_MINER_ABI from "abis/GreenBlockMiner.json";
import MINER_POOL_ABI from "abis/GreenBlockMinerPool.json";
import NFT_POOL_ABI from "abis/GreenBlockNFTPool.json";
import POOL_ABI from "abis/GreenBlockPool.json";
import SALES_ABI from "abis/GreenBlockSale.json";
import PRESALE_NFT_ABI from "abis/presaleNFT.json";
import {
  ArgentWalletDetector,
  DataNFTSale,
  EnsPublicResolver,
  EnsRegistrar,
  Erc20,
  Erc721,
  Erc1155,
  GreenBlockCapitalPool,
  GreenBlockCave,
  GreenBlockCavePool,
  GreenBlockConversor,
  GreenBlockDistributor,
  GreenBlockJamonStake,
  GreenBlockMiner,
  GreenBlockMinerPool,
  GreenBlockNFT,
  GreenBlockNFTPool,
  GreenBlockPool,
  GreenBlockSale,
  PresaleNFT,
  Weth,
} from "abis/types";
import WETH_ABI from "abis/weth.json";
import {
  CAVE_POOL_ADDRESS,
  DATA_NFT_ADDRESS,
  DATA_PRESALE_ADDRESS,
  DISTRIBUTOR_ADDRESS,
  GBC_DISTRIBUTOR_ADDRESS,
  GBC_POOL_ADDRESS,
  GBT_NFT_ADDRESS,
  GBT_PRESALE_ADDRESS,
  LOCKER_ADDRESS,
  MINER_POOL_ADDRESS,
  NFT_CAVE_ADDRESS,
  NFT_MINER_ADDRESS,
  NFT_POOL_ADDRESS,
  NFT_POOL_CONVERSOR_ADDRESS,
  NFT_VIP_ADDRESS,
  POOL_ADDRESS,
  SALES_ADDRESS,
} from "constants/addresses";
import { WRAPPED_NATIVE_CURRENCY } from "constants/tokens";
import { useMemo } from "react";
import {
  NonfungiblePositionManager,
  Quoter,
  QuoterV2,
  TickLens,
  UniswapInterfaceMulticall,
} from "types/v3";
import { V3Migrator } from "types/v3/V3Migrator";
import { getContract } from "utils";

const { abi: IUniswapV2PairABI } = IUniswapV2PairJson;
const { abi: IUniswapV2Router02ABI } = IUniswapV2Router02Json;
const { abi: QuoterABI } = QuoterJson;
const { abi: QuoterV2ABI } = QuoterV2Json;
const { abi: TickLensABI } = TickLensJson;
const { abi: MulticallABI } = UniswapInterfaceMulticallJson;
const { abi: NFTPositionManagerABI } = NonfungiblePositionManagerJson;
const { abi: V2MigratorABI } = V3MigratorJson;

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === "string") address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      return getContract(
        address,
        ABI,
        provider,
        withSignerIfPossible && account ? account : undefined
      );
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [
    addressOrAddressMap,
    ABI,
    provider,
    chainId,
    withSignerIfPossible,
    account,
  ]) as T;
}

export function useV2MigratorContract() {
  return useContract<V3Migrator>(V3_MIGRATOR_ADDRESSES, V2MigratorABI, true);
}

export function useTokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract<Erc20>(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(withSignerIfPossible?: boolean) {
  const { chainId } = useWeb3React();
  return useContract<Weth>(
    chainId ? WRAPPED_NATIVE_CURRENCY[chainId]?.address : undefined,
    WETH_ABI,
    withSignerIfPossible
  );
}

export function useERC721Contract(nftAddress?: string) {
  return useContract<Erc721>(nftAddress, ERC721_ABI, false);
}

export function useERC1155Contract(nftAddress?: string) {
  return useContract<Erc1155>(nftAddress, ERC1155_ABI, false);
}

export function useArgentWalletDetectorContract() {
  return useContract<ArgentWalletDetector>(
    ARGENT_WALLET_DETECTOR_ADDRESS,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  );
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean) {
  return useContract<EnsRegistrar>(
    ENS_REGISTRAR_ADDRESSES,
    ENS_ABI,
    withSignerIfPossible
  );
}

export function useENSResolverContract(
  address: string | undefined,
  withSignerIfPossible?: boolean
) {
  return useContract<EnsPublicResolver>(
    address,
    ENS_PUBLIC_RESOLVER_ABI,
    withSignerIfPossible
  );
}

export function useBytes32TokenContract(
  tokenAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function useEIP2612Contract(tokenAddress?: string): Contract | null {
  return useContract(tokenAddress, EIP_2612, false);
}

export function usePairContract(
  pairAddress?: string,
  withSignerIfPossible?: boolean
): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible);
}

export function useV2RouterContract(): Contract | null {
  return useContract(V2_ROUTER_ADDRESS, IUniswapV2Router02ABI, true);
}

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESSES,
    MulticallABI,
    false
  ) as UniswapInterfaceMulticall;
}

export function useV3NFTPositionManagerContract(
  withSignerIfPossible?: boolean
): NonfungiblePositionManager | null {
  return useContract<NonfungiblePositionManager>(
    NONFUNGIBLE_POSITION_MANAGER_ADDRESSES,
    NFTPositionManagerABI,
    withSignerIfPossible
  );
}

export function useQuoter(useQuoterV2: boolean) {
  return useContract<Quoter | QuoterV2>(
    QUOTER_ADDRESSES,
    useQuoterV2 ? QuoterV2ABI : QuoterABI
  );
}

export function useTickLens(): TickLens | null {
  const { chainId } = useWeb3React();
  const address = chainId ? TICK_LENS_ADDRESSES[chainId] : undefined;
  return useContract(address, TickLensABI) as TickLens | null;
}

export function useVipNFTContract() {
  return useContract<GreenBlockCave>(NFT_VIP_ADDRESS, NFT_CAVE_ABI, true);
}
export function useCaveNFTContract() {
  return useContract<GreenBlockCave>(NFT_CAVE_ADDRESS, NFT_CAVE_ABI, true);
}

export function useMinerNFTContract() {
  return useContract<GreenBlockMiner>(NFT_MINER_ADDRESS, NFT_MINER_ABI, true);
}

export function useSalesContract() {
  return useContract<GreenBlockSale>(SALES_ADDRESS, SALES_ABI, true);
}

export function useMinerPoolContract() {
  return useContract<GreenBlockMinerPool>(
    MINER_POOL_ADDRESS,
    MINER_POOL_ABI,
    true
  );
}

export function useCavePoolContract() {
  return useContract<GreenBlockCavePool>(
    CAVE_POOL_ADDRESS,
    CAVE_POOL_ABI,
    true
  );
}

export function useDistributorContract() {
  return useContract<GreenBlockDistributor>(
    DISTRIBUTOR_ADDRESS,
    DISTRIBUTOR_ABI,
    true
  );
}

export function useLockerContract() {
  return useContract<GreenBlockJamonStake>(LOCKER_ADDRESS, LOCKER_ABI, true);
}

export function usePoolContract() {
  return useContract<GreenBlockPool>(POOL_ADDRESS, POOL_ABI, true);
}

export function usePresaleNFTContract() {
  return useContract<PresaleNFT>(GBT_PRESALE_ADDRESS, PRESALE_NFT_ABI, true);
}

export function useGbcNftContract() {
  return useContract<GreenBlockNFT>(GBT_NFT_ADDRESS, NFT_CAVE_ABI, true);
}

export function useNftPoolContract() {
  return useContract<GreenBlockNFTPool>(NFT_POOL_ADDRESS, NFT_POOL_ABI, true);
}

export function useNFTConversor() {
  return useContract<GreenBlockConversor>(
    NFT_POOL_CONVERSOR_ADDRESS,
    NFT_CONVERSOR_ABI,
    true
  );
}

/* GBC contracts */

export function useGbcPoolContract() {
  return useContract<GreenBlockCapitalPool>(
    GBC_POOL_ADDRESS,
    GBC_POOL_ABI,
    true
  );
}

export function useGbcDistributorContract() {
  return useContract<GreenBlockDistributor>(
    GBC_DISTRIBUTOR_ADDRESS,
    DISTRIBUTOR_ABI,
    true
  );
}

/* Data contracts */

export function usePresaleDataNFTContract() {
  return useContract<DataNFTSale>(
    DATA_PRESALE_ADDRESS,
    DATA_PRESALE_NFT_ABI,
    true
  );
}

export function useDataNftContract() {
  return useContract<GreenBlockNFT>(DATA_NFT_ADDRESS, NFT_CAVE_ABI, true);
}
