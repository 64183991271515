import { useWeb3React } from "@web3-react/core";
import erc20Abi from "abis/DataNFTSale.json";
import { DATA_PRESALE_ADDRESS } from "constants/addresses";
import { isSupportedChain } from "constants/chains";
import useRefresh from "hooks/useRefresh";
import { useCallback, useEffect, useState } from "react";
import Multicall from "utils/multicall";

import { PresaleDetails } from "../types";

const usePublicNFTData = (): PresaleDetails => {
  const { chainId = 56 } = useWeb3React();
  const { fastRefresh } = useRefresh();
  const [state, setState] = useState({
    totalSold1: 0,
    totalSold2: 0,
    startTime: 0,
    endTime: 0,
    currentPhase: 1,
    whitelistEnabled: true,
  });

  const chainAllowed = isSupportedChain(chainId) ? chainId : 56;

  const presaleAddress = DATA_PRESALE_ADDRESS[chainAllowed];

  const fetchData = useCallback(async () => {
    const UserCalls = [
      { address: presaleAddress, name: "totalSold1", params: [] },
      { address: presaleAddress, name: "totalSold2", params: [] },
      { address: presaleAddress, name: "startTime", params: [] },
      { address: presaleAddress, name: "endTime", params: [] },
      { address: presaleAddress, name: "getCurrentPhase", params: [] },
      { address: presaleAddress, name: "whitelistEnabled", params: [] },
    ];

    const [
      totalSold1,
      totalSold2,
      startTime,
      endTime,
      getCurrentPhase,
      whitelistEnabled,
    ] = await Multicall(chainAllowed, erc20Abi, UserCalls);

    setState((prev) => ({
      ...prev,
      totalSold1: Number(totalSold1[0]),
      totalSold2: Number(totalSold2[0]),
      startTime: Number(startTime[0]),
      endTime: Number(endTime[0]),
      currentPhase: Number(getCurrentPhase[0]),
      whitelistEnabled: Boolean(whitelistEnabled[0]),
    }));
  }, [chainAllowed, presaleAddress]);

  useEffect(() => {
    fetchData();
  }, [fetchData, fastRefresh]);

  return { ...state, fetchData };
};

export default usePublicNFTData;
