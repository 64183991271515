import { useWeb3React } from "@web3-react/core";
import erc20Abi from "abis/DataNFTSale.json";
import BigNumber from "bignumber.js";
import { DATA_PRESALE_ADDRESS } from "constants/addresses";
import { isSupportedChain } from "constants/chains";
import useRefresh from "hooks/useRefresh";
import { useCallback, useEffect, useState } from "react";
import { BIG_ZERO } from "utils/bigNumber";
import Multicall from "utils/multicall";

import { WalletDetails } from "../types";

const useUserNFTData = (): WalletDetails => {
  const { slowRefresh } = useRefresh();
  const { account, chainId = 56 } = useWeb3React();
  const [state, setState] = useState({
    bnbBalance: BIG_ZERO,
    isWhitelisted: false,
    ownerIds: [],
  });

  const chainAllowed = isSupportedChain(chainId) ? chainId : 56;

  const presaleAddress = DATA_PRESALE_ADDRESS[chainAllowed];

  const fetchUserData = useCallback(async () => {
    const UserCalls = [
      { address: presaleAddress, name: "bnbBalance", params: [account] },
      { address: presaleAddress, name: "isWhitelisted", params: [account] },
      { address: presaleAddress, name: "getIdsOfBuyer", params: [account] },
    ];

    const [bnbBalance, isWhitelisted, getIdsOfBuyer] = await Multicall(
      chainAllowed,
      erc20Abi,
      UserCalls
    );

    setState((prev) => ({
      ...prev,
      bnbBalance: new BigNumber(bnbBalance[0].toString() ?? "0"),
      isWhitelisted: Boolean(isWhitelisted[0]),
      ownerIds: getIdsOfBuyer[0],
    }));
  }, [account, chainAllowed, presaleAddress]);

  useEffect(() => {
    if (account) {
      fetchUserData();
    }
  }, [account, fetchUserData, slowRefresh]);

  return { ...state, fetchUserData };
};

export default useUserNFTData;
