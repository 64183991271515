/* eslint-disable react/prop-types */
import { t, Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import NFT from "assets/images/home/nft.png";
import BNB from "assets/images/tokens/BNB.png";
import BigNumber from "bignumber.js";
import { useAccountDrawer } from "components/AccountDrawer";
import { ButtonPhase, ButtonPrimary } from "components/Button";
import {
  BorderCard,
  BorderCardSecondary,
  DarkCard,
  LightCard,
  RadiantPrimaryCard,
} from "components/Card";
import { AutoColumn } from "components/Column";
import ProgressBar from "components/ProgressBar";
import { RowBetween, RowFixed } from "components/Row";
import { DATA_NFT_ADDRESS } from "constants/addresses";
import { usePresaleDataNFTContract } from "hooks/useContract";
import { useCallback, useMemo, useState } from "react";
import Countdown from "react-countdown";
import { zeroPad } from "react-countdown";
import { ExternalLink as ExternalLinkIcon } from "react-feather";
import styled from "styled-components/macro";
import { ExternalLink, ThemedText } from "theme";
import { ExplorerDataType, getExplorerLink } from "utils/getExplorerLink";
import { isMobile } from "utils/userAgent";

import usePublicNFTData from "./hooks/usePublicNFTData";
import useUserNFTData from "./hooks/useUserNFTData";

const Label = styled(({ ...props }) => (
  <ThemedText.DeprecatedLabel {...props} />
))`
  display: flex;
  font-size: 20px;
  justify-content: flex-start;
  align-items: center;
`;

const PageWrapper = styled.div`
  max-width: 1200px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    max-width: 800px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    max-width: 500px;
  `};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    gap: 0.5rem;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    gap: 0.5rem;
  `};
`;

const Divider = styled.div`
  width: 100px;
  height: 2px;
  background: #070f3a;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    width: 60px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 60px;
  `};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const ResponsiveBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex-direction: column;
    align-items: center;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`;

const Container = styled.div`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    padding: 10px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding: 10px;
  `};
`;

const ResponsiveData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    flex-direction: column;
    align-items: center;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    align-items: center;
  `};
`;

const BodyCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  padding-right: 4rem;
  padding-left: 4rem;
`;

const PriceText = styled.div`
  position: absolute;
  bottom: 80%;
  font-size: 24px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    font-size: 16px;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    font-size: 16px;
  `};
`;

const currentTimestamp = () => new Date().getTime();

export default function Home() {
  const { chainId = 56, account } = useWeb3React();
  const [confirming, setConfirming] = useState(false);
  const [phase, setPhase] = useState(1);
  const presaleContract = usePresaleDataNFTContract();
  const [, toggleAccountDrawer] = useAccountDrawer();

  const {
    totalSold1,
    totalSold2,
    startTime,
    endTime,
    currentPhase,
    whitelistEnabled,
    fetchData,
  } = usePublicNFTData();
  const { bnbBalance, isWhitelisted, ownerIds, fetchUserData } =
    useUserNFTData();

  const hardcap = 200;
  const start = startTime * 1000;
  const end = endTime * 1000;

  const totalSold = totalSold1 + totalSold2;

  const totalSupply = 100;
  const minted = phase === 0 ? totalSold1 : totalSold2;

  const progress = useMemo(() => {
    return (totalSold / hardcap) * 100;
  }, [totalSold]);

  const isStarted = useMemo(() => {
    return start < currentTimestamp();
  }, [start]);

  const isFinished = useMemo(() => {
    return end < currentTimestamp();
  }, [end]);

  const price = phase === 0 ? 1 : 1.5;
  const BigPrice = useMemo(() => {
    return phase === 0
      ? new BigNumber("1000000000000000000")
      : new BigNumber("1500000000000000000");
  }, [phase]);

  const limit = isMobile ? 8 : 25;

  const rowsIds: number[][] = useMemo(() => {
    const array = [];
    if (ownerIds && ownerIds.length > 0) {
      for (let i = 0; i < ownerIds.length; i += limit) {
        const row = ownerIds.slice(i, i + limit);
        array.push(row);
      }
    }
    return array;
  }, [limit, ownerIds]);

  const renderCountdown = (props: any) => {
    return (
      <Row>
        {isMobile && (
          <ThemedText.SubHeaderText>{textTime}</ThemedText.SubHeaderText>
        )}
        <RadiantPrimaryCard width={isMobile ? "60px" : "100px"}>
          <Column>
            <ThemedText.MediumText>
              <span>{zeroPad(props.days)}</span>
            </ThemedText.MediumText>
            <Divider />
            <ThemedText.MediumText>
              <Trans>Days</Trans>
            </ThemedText.MediumText>
          </Column>
        </RadiantPrimaryCard>
        <RadiantPrimaryCard width={isMobile ? "60px" : "100px"}>
          <Column>
            <ThemedText.MediumText>
              <span>{zeroPad(props.hours)}</span>
            </ThemedText.MediumText>
            <Divider />
            <ThemedText.MediumText>
              <Trans>Hours</Trans>
            </ThemedText.MediumText>
          </Column>
        </RadiantPrimaryCard>
        <RadiantPrimaryCard width={isMobile ? "60px" : "100px"}>
          <Column>
            <ThemedText.MediumText>
              <span>{zeroPad(props.minutes)}</span>
            </ThemedText.MediumText>
            <Divider />
            <ThemedText.MediumText>
              <Trans>Min</Trans>
            </ThemedText.MediumText>
          </Column>
        </RadiantPrimaryCard>
        <RadiantPrimaryCard width={isMobile ? "60px" : "100px"}>
          <Column>
            <ThemedText.MediumText>
              <span>{zeroPad(props.seconds)}</span>
            </ThemedText.MediumText>
            <Divider />
            <ThemedText.MediumText>
              <Trans>Sec</Trans>
            </ThemedText.MediumText>
          </Column>
        </RadiantPrimaryCard>
      </Row>
    );
  };

  const textTime = useMemo(() => {
    if (isStarted && !isFinished) return t`Ends in`;
    if (!isStarted && !isFinished) return t`Start in`;
    return t`Finished`;
  }, [isStarted, isFinished]);

  const isPrimaryEnabled = () => {
    if (phase == 0) {
      return false;
    }
    if (!isStarted) {
      return false;
    }
    if (isFinished) {
      return false;
    }
    if (ownerIds && ownerIds.length > 0) {
      return false;
    }
    /* if (currentPhase == 1 && whitelistEnabled && !isWhitelisted) {
      return false;
    } */
    if (phase == 0 && totalSold1 >= totalSupply) {
      return false;
    }
    if (phase == 1 && totalSold2 >= totalSupply) {
      return false;
    }
    if (currentPhase == 1 && phase == 1) {
      return false;
    }
    if (BigPrice.gt(bnbBalance)) {
      return false;
    }
    if (confirming) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    if (!account) {
      return t`Connect`;
    }
    if (phase == 0) {
      return t`Sold out`;
    }
    if (!isStarted) {
      return t`Upcoming`;
    }
    if (isFinished) {
      return t`Finished`;
    }
    if (ownerIds && ownerIds.length > 0) {
      return t`Bought`;
    }
    /* if (currentPhase == 1 && whitelistEnabled && !isWhitelisted) {
      return t`Not in WL`;
    } */
    if (phase == 0 && totalSold1 >= totalSupply) {
      return t`Sold out`;
    }
    if (phase == 1 && totalSold2 >= totalSupply) {
      return t`Sold out`;
    }
    if (currentPhase == 1 && phase == 1) {
      return t`Upcoming`;
    }
    if (BigPrice.gt(bnbBalance)) {
      return t`Insufficient balance`;
    }

    if (confirming) {
      return t`Confirming...`;
    }

    return t`Mint now`;
  };

  const handleBuy = useCallback(async () => {
    try {
      setConfirming(true);
      const tx = await presaleContract?.buy({ value: BigPrice.toPrecision() });
      await tx?.wait();
      fetchData();
      fetchUserData();
      setConfirming(false);
    } catch (e) {
      setConfirming(false);
      console.error(e);
    } finally {
      setConfirming(false);
    }
  }, [BigPrice, fetchData, fetchUserData, presaleContract]);

  const onClickPrimary = () => {
    if (!account) {
      toggleAccountDrawer();
      return;
    }

    handleBuy();
  };

  return (
    <PageWrapper>
      <ResponsiveBox>
        <Countdown
          date={new Date(isStarted ? end : start)}
          renderer={(props: any) => renderCountdown(props)}
          /* onComplete={fetchData} */
        />

        {isMobile && (
          <img
            src={NFT}
            alt="nft-img"
            width="300px"
            style={{ borderRadius: "16px" }}
          />
        )}
        <Row>
          <ButtonPhase active={phase === 0} onClick={() => setPhase(0)}
            disabled={true} >
            <Trans>Phase 1</Trans>
          </ButtonPhase>
          <ButtonPhase
            active={phase === 1}
            onClick={() => setPhase(1)}
          >
            <Trans>Phase 2</Trans>
          </ButtonPhase>
        </Row>
      </ResponsiveBox>
      <ResponsiveBox>
        {!isMobile && (
          <ThemedText.SubHeaderText>{textTime}</ThemedText.SubHeaderText>
        )}
        {!isMobile && (
          <ThemedText.SubHeaderText>
            ({totalSold}/{hardcap})
          </ThemedText.SubHeaderText>
        )}
      </ResponsiveBox>
      <Container>
        {isMobile && (
          <Row style={{ justifyContent: "flex-end", marginTop: "2rem" }}>
            <ThemedText.SubHeaderText>
              ({totalSold}/{hardcap})
            </ThemedText.SubHeaderText>
          </Row>
        )}
        <Row
          style={{
            justifyContent: "flex-end",
            marginTop: "1rem",
            marginBottom: "2rem",
          }}
        >
          <ProgressBar progress={progress} />
        </Row>
        <ResponsiveData>
          {!isMobile && (
            <img
              src={NFT}
              alt="nft-img"
              width="400px"
              style={{ borderRadius: "16px" }}
            />
          )}
          <AutoColumn gap="2rem" style={{ width: isMobile ? "100%" : "600px" }}>
            <BorderCard>
              <BodyCard>
                <RowBetween>
                  <ThemedText.MediumText>
                    <Trans>Phase supply</Trans>
                  </ThemedText.MediumText>
                  <ThemedText.MediumText>{totalSupply}</ThemedText.MediumText>
                </RowBetween>
                <RowBetween>
                  <ThemedText.MediumText>
                    <Trans>Minted</Trans>
                  </ThemedText.MediumText>
                  <ThemedText.MediumText>{minted}</ThemedText.MediumText>
                </RowBetween>
                <RowBetween>
                  <ThemedText.MediumText>
                    <Trans>Per txn</Trans>
                  </ThemedText.MediumText>
                  <ThemedText.MediumText>1</ThemedText.MediumText>
                </RowBetween>
              </BodyCard>
            </BorderCard>
            <BorderCardSecondary>
              <BodyCard>
                <PriceText>
                  <Trans>Price</Trans>
                </PriceText>
                <Row>
                  <img src={BNB} alt="bnb-icon" width={isMobile ? 24 : 64} />
                  <ThemedText.LargeText>
                    {price} BNB / DATA TOKEN
                  </ThemedText.LargeText>
                </Row>
              </BodyCard>
            </BorderCardSecondary>
            <ExternalLink
              id="pool-link"
              href={getExplorerLink(
                chainId,
                DATA_NFT_ADDRESS[chainId],
                ExplorerDataType.ADDRESS
              )}
              target="_blank"
            >
              <Row style={{ gap: "0.2rem", fontSize: "20px" }}>
                <Trans>NFT Contract</Trans>
                <ExternalLinkIcon />
              </Row>
            </ExternalLink>
            <Row>
              <ButtonPrimary
                disabled={!isPrimaryEnabled()}
                onClick={onClickPrimary}
              >
                {getPrimaryText()}
              </ButtonPrimary>
            </Row>
          </AutoColumn>
        </ResponsiveData>
        {ownerIds && ownerIds.length > 0 && (
          <DarkCard mt="1.5rem">
            <AutoColumn gap="md">
              <RowFixed>
                <Label display="flex" style={{ marginRight: "12px" }}>
                  <Trans>Your ids</Trans>
                </Label>
              </RowFixed>
              <LightCard padding="12px" width="100%">
                <ThemedText.BodyPrimary style={{ wordWrap: "break-word" }}>
                  {rowsIds.map((row, index) => (
                    <div key={index}>{row.join(",")}</div>
                  ))}
                </ThemedText.BodyPrimary>
              </LightCard>
            </AutoColumn>
          </DarkCard>
        )}
        {/* <MintHistory variant={bnbBalance} /> */}
      </Container>
    </PageWrapper>
  );
}
