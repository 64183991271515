import React from "react";
import styled from "styled-components/macro";

interface ProgressBarProps {
  progress: number; // El progreso de la barra de progreso (0-100)
}

const ProgressBarWrapper = styled.div`
  width: 50%;
  background-color: #f0f0f0;
  border-radius: 4px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    width: 100%;
  `};

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    width: 100%;
  `};
`;

const ProgressBarFill = styled.div<{ progress: number }>`
  width: ${(props) => props.progress}%;
  height: 13px;
  background-color: #03f1f6;
  border-radius: 5px;
`;

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarFill progress={progress} />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
